ACTIVE_RETURN:
  DATA_TYPE:
    BENCHMARK: 'Bench'

GLOBAL:
  STRATEGY: 'QIS'
  THEMATICS: 'Custom Basket'

  STRATEGY_TYPE_NAME:
    STRATEGY: 'QIS '
    THEMATIC: 'Custom Basket | Custom Baskets'
